import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import products from "../data/productIndex";
import "../styles/components/product-images.scss";

interface ImageFileProps {
  name: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}

interface ProductIndexQueryProps {
  productImages: {
    files: ImageFileProps[];
  };
}

const ProductIndex: React.FC = () => {
  const data: ProductIndexQueryProps = useStaticQuery(graphql`
    query ProductIndexQuery {
      productImages: allFile(
        filter: { relativePath: { regex: "/^images/products/.+$/" } }
        sort: { order: ASC, fields: name }
      ) {
        files: nodes {
          name
          childImageSharp {
            fluid(quality: 85, maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  // Select image files with name matching the given product name.
  // Filename format is <product name>_<image number>.<extension>
  const productImages = products.map(({ name, label, description }, index) => {
    const slug = name.split("_").join("-");
    const imageFile = data.productImages.files.find(
      file => file.name.match(new RegExp(`${name}_0`)) !== null
    );
    if (imageFile !== undefined) {
      const imageData = imageFile.childImageSharp.fluid;

      return (
        <div key={index} className="col-md-4">
          <div className="card shadow h-100">
            <Link to={`/products/${slug}`}>
              <Img className="card-image" fluid={imageData} />
            </Link>
            <div className="card-body">
              <h2>
                <Link to={`/products/${slug}`}>{label}</Link>
              </h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <div className="container mb-5">
      <div className="row">{productImages}</div>
    </div>
  );
};

export default ProductIndex;
