import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import productsData from "../data/productsData";

interface ProductDataProps {
  product: string;
}

interface DatasheetsQueryProps {
  datasheets: {
    datasheetFiles: {
      name: string;
      publicURL: string;
    }[];
  };
}

const ProductData: React.FC<ProductDataProps> = ({ product }) => {
  const productData = productsData.find(({ name }) => name === product);
  if (productData === undefined) {
    throw `productData for ${product} is undefined.`;
  }

  const {
    datasheets: { datasheetFiles }
  }: DatasheetsQueryProps = useStaticQuery(graphql`
    query DatasheetsQuery {
      datasheets: allFile(
        filter: { relativePath: { regex: "/^datasheets/.+$/" } }
      ) {
        datasheetFiles: nodes {
          name
          publicURL
        }
      }
    }
  `);

  const datasheetFile = datasheetFiles.find(({ name }) => name === product);
  if (datasheetFile === undefined) {
    throw `datasheetFile for ${product} is undefined.`;
  }

  const {
    servingsPerPackage,
    servingSize,
    energy,
    protein,
    fatTotal,
    fatSaturated,
    carbsTotal,
    carbsSugars,
    minerals,
    ingredients,
    allergens
  } = productData;

  const row = (
    label: string,
    amounts: Array<string>,
    className?: string,
    key?: any
  ) => (
    <tr key={key} className={className}>
      <th>{label}</th>
      <td className="text-right">{amounts[0]}</td>
      <td className="text-right">{amounts[1]}</td>
    </tr>
  );

  const emptyRow = (label: string, className?: string) => (
    <tr className={className}>
      <th>{label}</th>
      <td></td>
      <td></td>
    </tr>
  );

  return (
    <>
      <div className="ingredients">
        <h3>Ingredients</h3>
        <p>{ingredients}</p>
        <p>
          <strong>{allergens}</strong>
        </p>
      </div>
      <div className="nutrition-information">
        <h3>Nutrition Information</h3>
        <p>
          Servings per package: {servingsPerPackage}
          <br />
          Serving size: {servingSize}
        </p>
        <table className="nutrition-table mb-3 w-100">
          <thead>
            <tr>
              <th></th>
              <th className="text-right">
                Quantity
                <br />
                per Serving
              </th>
              <th className="text-right">
                Quantity
                <br />
                per 100g
              </th>
            </tr>
          </thead>
          <tbody className="border-bottom">
            {row("Energy", energy, "border-top")}
            {row("Protein", protein, "border-top")}
            {emptyRow("Fat", "border-top")}
            {row("- Total", fatTotal)}
            {row("- Saturated", fatSaturated)}
            {emptyRow("Carbohydrates", "border-top")}
            {row("- Total", carbsTotal)}
            {row("- Sugars", carbsSugars)}
            {minerals &&
              minerals.map(([label, ...amounts], index) =>
                index === 0
                  ? row(label, amounts, "border-top", index)
                  : row(label, amounts, undefined, index)
              )}
          </tbody>
        </table>
      </div>
      <div className="">
        <a href={datasheetFile.publicURL}>
          Download datasheet for this product
        </a>
      </div>
    </>
  );
};

export default ProductData;
