import PageTitle from "..\\..\\..\\..\\src\\components\\PageTitle";
import ProductData from "..\\..\\..\\..\\src\\components\\ProductData";
import ProductImages from "..\\..\\..\\..\\src\\components\\ProductImages";
import SuppliedFrozen from "..\\..\\..\\..\\src\\components\\SuppliedFrozen";
import SVGImage from "..\\..\\..\\..\\src\\components\\SVGImage";
import * as React from 'react';
export default {
  PageTitle,
  ProductData,
  ProductImages,
  SuppliedFrozen,
  SVGImage,
  React
};