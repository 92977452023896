const products = [
  {
    name: "chimichangas",
    label: "Chimichangas (Burritos)",
    description: `
      Our chimichangas are handmade, with a corn tortilla rolled around a generous Mexican-style chicken & corn filling,
      which has a spicy but mild flavour.
    `
  },
  {
    name: "pancakes",
    label: "Pancakes",
    description: `
    Our golden, fluffy pancakes are handmade. Available in two varieties: Plain and Buttermilk.
    `
  },
  {
    name: "gluten_free_waffles",
    label: `Waffles "Gluten-Free"`,
    description: `
      Handmade with care and quality ingredients.  Light, fluffy and crispy when cooked.
    `
  }
];

export default products;
