import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import "../styles/components/product-images.scss";

interface ImageFileProps {
  name: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}

interface ProductImagesProps {
  product: string;
}

interface ProductImagesQueryProps {
  productImages: {
    files: ImageFileProps[];
  };
}

const ProductImages: React.FC<ProductImagesProps> = ({ product }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const data: ProductImagesQueryProps = useStaticQuery(graphql`
    query ProductImagesQuery {
      productImages: allFile(
        filter: { relativePath: { regex: "/^images/products/.+$/" } }
        sort: { order: ASC, fields: name }
      ) {
        files: nodes {
          name
          childImageSharp {
            fluid(quality: 85, maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  // Select image files with name matching the given product name.
  // Filename format is <product name>_<image number>.<extension>
  const imageFiles = data.productImages.files.filter(
    file => file.name.match(new RegExp(`${product}_[1-9]+`)) !== null
  );

  const largeImageData = imageFiles[imageIndex].childImageSharp.fluid;
  const thumbnailColSpan = 12 / imageFiles.length;

  const thumbnails = imageFiles.map((imageFile, imageIndex) => {
    const imageData = imageFile.childImageSharp.fluid;

    return (
      <div key={imageIndex} className={`col col-${thumbnailColSpan}`}>
        <div
          className="thumbnail-wrapper"
          onClick={() => {
            setImageIndex(imageIndex);
          }}
        >
          <Img className="thumbnail shadow" fluid={imageData} />
        </div>
      </div>
    );
  });

  return (
    <div className="product-images mb-5">
      <div
        className="large-image-wrapper"
        onClick={() => {
          setImageIndex(imageIndex);
        }}
      >
        <Img className="large-image shadow" fluid={largeImageData} />
      </div>
      <div className="thumbnails">
        <div className="row">{thumbnails}</div>
      </div>
      <div className="font-italic">Photos show serving suggestions</div>
    </div>
  );
};

export default ProductImages;
