import PageTitle from "..\\..\\..\\..\\src\\components\\PageTitle";
import ProductData from "..\\..\\..\\..\\src\\components\\ProductData";
import ProductImages from "..\\..\\..\\..\\src\\components\\ProductImages";
import SuppliedFrozen from "..\\..\\..\\..\\src\\components\\SuppliedFrozen";
import HACCPCertified from "..\\..\\..\\..\\src\\components\\HACCPCertified";
import SVGImage from "..\\..\\..\\..\\src\\components\\SVGImage";
import Tabs from "..\\..\\..\\..\\src\\components\\Tabs";
import pancakesSizesImageURL from "..\\..\\..\\..\\src\\assets\\images\\pancake_sizes.jpg";
import * as React from 'react';
export default {
  PageTitle,
  ProductData,
  ProductImages,
  SuppliedFrozen,
  HACCPCertified,
  SVGImage,
  Tabs,
  pancakesSizesImageURL,
  React
};