import * as React from "react";

interface DistributorListingProps {
  name: string;
  streetLine1: string;
  streetLine2?: string;
  locality: string;
  administrativeArea: string;
  country: string;
  phone: string;
  email: string;
  website: string;
}

const Root: React.FC<DistributorListingProps> = ({
  name,
  streetLine1,
  streetLine2,
  locality,
  administrativeArea,
  country,
  phone,
  email,
  website
}) => {
  const hasContactDetails = phone || email;
  const hasValidAddress =
    streetLine1 && locality && administrativeArea && country;

  return (
    <div className="col-md-6 col-lg-6 mb-4">
      <div className="card shadow h-100">
        <div className="card-body">
          <h3>{name}</h3>
          {website && (
            <p>
              Website: <a href={website}>{website}</a>
            </p>
          )}

          {hasContactDetails && (
            <p>
              {phone && `Phone:&nbsp;${phone}`}
              <br />
              {email && `Email:&nbsp;${email}`}
            </p>
          )}

          {hasValidAddress && (
            <p>
              {streetLine1}
              <br />
              {streetLine2 && (
                <>
                  {streetLine2}
                  <br />
                </>
              )}
              {locality},&nbsp;{administrativeArea}
              <br />
              {country}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Root;
