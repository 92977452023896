import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import classnames from "classnames";
import logo from "../assets/logo_red.svg";
import { homepageBannerMenuItems as menuItems } from "../data/menus";
import BackgroundImage from "gatsby-background-image";
import { FluidObject } from "gatsby-image";
import "../styles/components/homepage-banner.scss";

interface HomepageBannerQueryProps {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  bannerImages: {
    files: {
      name: string;
      childImageSharp: {
        fluid: FluidObject;
      };
    }[];
  };
}

const HomepageBanner: React.FC = ({ children }) => {
  const data: HomepageBannerQueryProps = useStaticQuery(graphql`
    query HomepageBannerQuery {
      site {
        siteMetadata {
          title
        }
      }
      bannerImages: allFile(
        filter: { relativePath: { regex: "/^images/homepage_banner.+$/" } }
      ) {
        files: nodes {
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const bannerImages = data.bannerImages.files.map((file, index) => (
    <BackgroundImage
      key={index}
      className="h-100 shadow"
      Tag="div"
      fluid={file.childImageSharp.fluid}
      backgroundColor="#040e18"
    />
  ));

  const buttonGrid = menuItems.map(
    ({ label, url }, index) =>
      url && (
        <div key={index} className="col-lg-6 px-lg-2">
          <Link
            className={classnames(
              "btn btn-light text-qgc-red shadow w-100 mb-lg-3",
              index < menuItems.length - 1 && "mb-3"
            )}
            to={url}
          >
            {label}
          </Link>
        </div>
      )
  );

  return (
    <div className="homepage-banner">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-4 d-none d-lg-block p-0">
            {bannerImages[0]}
          </div>
          <div className="col-12 col-lg-4">
            <div className="d-flex flex-column h-100">
              <div className="branding d-flex justify-content-center p-2 pt-3 pt-lg-5">
                <img
                  className="d-block mw-100 h-auto"
                  src={logo}
                  alt={data.site.siteMetadata.title}
                  width="260"
                />
              </div>
              <div className="flex-grow-1 d-flex flex-column justify-content-center">
                <div className="my-5 mt-lg-0 mb-lg-3 text-center">
                  <h1>{children}</h1>
                </div>

                <div className="row">
                  <div className="col-3 col-sm-4 d-lg-none p-0">
                    {bannerImages[0]}
                  </div>
                  <div className="col-6 col-sm-4 col-lg-12">
                    <div className="row px-lg-3 pb-lg-2">{buttonGrid}</div>
                  </div>
                  <div className="col-3 col-sm-4 d-lg-none p-0">
                    {bannerImages[1]}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-none d-lg-block p-0">
            {bannerImages[1]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageBanner;
