import React from "react";
import SVGImage from "./SVGImage";

const SuppliedFrozen: React.FC = () => (
  <div className="d-flex mb-3 align-items-center">
    <div className="flex-grow-0 pr-3">
      <SVGImage name="snowflake_icon" className="mw-100" />
    </div>
    <div className="flex-grow-1">
      <h2 className="h3">Supplied frozen</h2>
      <ul className="list-unstyled d-flex flex-column justify-content-center m-0">
        <li className="d-block">
          <span>✓</span> No waste
        </li>
        <li className="d-block">
          <span>✓</span> Long shelf life (18 months)
        </li>
        <li className="d-block">
          <span>✓</span> Easy to prepare
        </li>
        <li className="d-block">
          <span>✓</span> No dirty pans
        </li>
      </ul>
    </div>
  </div>
);

export default SuppliedFrozen;
