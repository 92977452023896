const products = [
  {
    name: "pancakes_plain_10cm",
    servingsPerPackage: "162",
    servingSize: "45g",
    energy: ["410kJ", "911kJ"],
    protein: ["2.7g", "5.9g"],
    fatTotal: ["2.4g", "5.3g"],
    fatSaturated: ["0.3g", "0.7g"],
    carbsTotal: ["16.1g", "35.8g"],
    carbsSugars: ["4.8g", "10.6g"],
    minerals: [["Sodium", "201mg", "447mg"]],
    ingredients: `
      Pancake Mix (Wheat Flour, Sugar, Canola Oil, Egg Powder, Raising Agents (500, 450, 541), Non-Fat Milk Solids, Wheat Starch,
      Preservative (281), Salt, Emulsifier (471), Flavour), Water.
    `,
    allergens: "Contains: Gluten (Wheat), Egg, Milk."
  },
  {
    name: "pancakes_plain_12cm",
    servingsPerPackage: "100",
    servingSize: "55g",
    energy: ["501kJ", "911kJ"],
    protein: ["3.2g", "5.9g"],
    fatTotal: ["2.9g", "5.3g"],
    fatSaturated: ["0.4g", "0.7g"],
    carbsTotal: ["19.7g", "35.8g"],
    carbsSugars: ["5.8g", "10.6g"],
    minerals: [["Sodium", "246mg", "447mg"]],
    ingredients: `
      Pancake Mix (Wheat Flour, Sugar, Canola Oil, Egg Powder, Raising Agents (500, 450, 541), Non-Fat Milk Solids, Wheat Starch,
      Preservative (281), Salt, Emulsifier (471), Flavour), Water.
    `,
    allergens: "Contains: Gluten (Wheat), Egg, Milk."
  },
  {
    name: "pancakes_plain_15cm",
    servingsPerPackage: "60",
    servingSize: "90g",
    energy: ["820kJ", "911kJ"],
    protein: ["5.3g", "5.9g"],
    fatTotal: ["4.8g", "5.3g"],
    fatSaturated: ["0.7g", "0.7g"],
    carbsTotal: ["32.2g", "35.8g"],
    carbsSugars: ["9.5g", "10.6g"],
    minerals: [["Sodium", "403mg", "447mg"]],
    ingredients: `
      Pancake Mix (Wheat Flour, Sugar, Canola Oil, Egg Powder, Raising Agents (500, 450, 541), Non-Fat Milk Solids, Wheat Starch,
      Preservative (281), Salt, Emulsifier (471), Flavour), Water.
    `,
    allergens: "Contains: Gluten (Wheat), Egg, Milk."
  },
  {
    name: "pancakes_buttermilk_12cm",
    servingsPerPackage: "100",
    servingSize: "55g",
    energy: ["495kJ", "899kJ"],
    protein: ["3.4g", "6.2g"],
    fatTotal: ["2.1g", "3.7g"],
    fatSaturated: ["0.4g", "0.7g"],
    carbsTotal: ["20.9g", "38.0g"],
    carbsSugars: ["6.2g", "11.3g"],
    minerals: [["Sodium", "226mg", "411mg"]],
    ingredients: `
      Buttermilk Pancake Mix (54%) (Wheat Flour, Sugar, Buttermilk Powder (Milk), Whey Protein (Milk), Mineral Salts (500, 450, 541),
      Whole Egg, Salt, Emulsifier (471), Natural Buttermilk Flavour, Water (43%), Canola Oil (2.5%)).
    `,
    allergens: "Contains: Gluten (Wheat), Egg, Milk."
  },
  {
    name: "chimichangas",
    servingsPerPackage: "1",
    servingSize: "180g",
    energy: ["1210kJ", "672kJ"],
    protein: ["14.9g", "8.3g"],
    fatTotal: ["5.1g", "2.8g"],
    fatSaturated: ["1.8g", "1.0g"],
    carbsTotal: ["43.6g", "24.2g"],
    carbsSugars: ["3.2g", "1.8g"],
    minerals: [["Sodium", "384mg", "213mg"]],
    ingredients: `
      Tortilla (Corn Flour, Water, Vegetable Gum (412), Acidity Regulator (338), Preservative (280), Humectant (1520)), Water, Seasoned
      Chicken [Corn Starch, Salt, Herbs & Spices, Seasoning (Salt, Sugar, Rice Flour, Dried Vegetables, Spices, Flavour Enhancer (621),
      Canola Oil, Citric Acid, Spice Extract), Garlic, Olive Oil, Mineral Salts (450, 451, 452)], Corn, Onions, Roast Chicken [Corn Starch,
      Salt, Herbs & Spices, Garlic, Olive Oil, Mineral Salts (450, 451, 452)], Tomatoes [Salt, Citric Acid], Textured Vegetable Protein
      [Soy], Capsicum, Tomato Paste [Citric Acid], Nacho Cheese Sauce (Cheese Powder, Wheat Flour, Thickener (1422), Milk Powder, Salt,
      Hydrolysed Vegetable Protein [Soy], Bell Pepper, Sugar, Vegetable Powders, Sugar, Flavour, Vegetable Gum (412), Spices, Flavour
      Enhancer (635)), Cheese [Tapioca Starch, Preservative (200)], Tomato Salsa [Onion, Water, Capsicum, Thickener (1422), Garlic,
      Coriander, Jalapeno Chilli, Vinegar, Salt, Herbs & Spices], Jalapeno Peppers [Water, Vinegar, Salt, Mineral Salt (509), Preservative
      (211)], Starch (Thickener (1422)), Chicken Booster (Salt, Sugar, Maize Starch, Chicken, Onion Powder, Yeast Extract, Flavour, Flavour
      Enhancer (635), Colour (100, 150c)), Garlic, Salt, Pepper.`,
    allergens: "Contains: Wheat, Milk, Soy."
  },
  {
    name: "gluten_free_waffles",
    servingsPerPackage: "50",
    servingSize: "approx 100g",
    energy: ["1080kJ", "1080kJ"],
    protein: ["6.5g", "6.5g"],
    fatTotal: ["6.2g", "6.2g"],
    fatSaturated: ["2.4g", "2.4g"],
    carbsTotal: ["43.1g", "43.1g"],
    carbsSugars: ["19.0g", "19.0g"],
    minerals: [["Sodium", "536mg", "536mg"]],
    ingredients: `
      Gluten Free Waffle Mix (Rice Flour, Sugar, Milk
      Powder, Buttermilk Powder, Maize Flour, Potato Starch, Milk Solids, Mineral Salts (500, 541), Tapioca Starch, Whole Egg, Flavour, Salt), Water, Canola Oil.
      Contains: Milk, Egg.`,
    allergens: "Contains: Wheat, Milk, Soy."
  },


];

export default products;
