export interface MenuItem {
  label: string;
  url?: string;
  subMenu?: MenuItem[];
}

const mainMenuItems: MenuItem[] = [
  {
    label: "About Us",
    url: "/about"
  },
  {
    label: "Our Foods",
    url: "/products"
  },
  {
    label: "Distributors",
    url: "/distributors"
  },
  {
    label: "Contact Us",
    url: "/contact"
  }
];

const homepageBannerMenuItems: MenuItem[] = [
  {
    label: "About Us",
    url: "/about"
  },
  {
    label: "Our Foods",
    url: "/products"
  },
  {
    label: "Distributors",
    url: "/distributors"
  },
  {
    label: "Contact Us",
    url: "/contact"
  }
];

export { mainMenuItems, homepageBannerMenuItems };
