import * as React from "react";
import certImageURL from "../assets/images/icg_cert_mark.png";

const HACCPCertified: React.FC = () => {
  return (
    <div className="d-flex">
        <div>
            <img src={certImageURL} alt="HACCP certification badge" height="120" />
        </div>
        <div className="flex-grow-1 ml-3">
            <h2 className="h3">Certified safety</h2>
            <p>Our products are HACCP certified.</p>
        </div>
    </div>
  );
};

export default HACCPCertified;
